<template>
	<div>
		<div v-for="(item, index) in block.buttons" :key="index">
			<dynamic-button :button="item" />
		</div>
	</div>
</template>

<script>
import DynamicButton from '~/components/panels/system/DynamicButton';

export default {
	name: 'PanelFooter',
	components: { DynamicButton },
	props: {
		block: { type: Object, required: true },
	},
};
</script>
