import PanelFooter from '~/components/panels/PanelFooter.vue';
import DynamicButton from '~/components/panels/system/DynamicButton.vue';

// @vue/component
export default {
	components: { PanelFooter, DynamicButton },
	inheritAttrs: false,
	props: {
		block: { type: Object, required: true },
		fetchedData: { type: Object, required: true },
	},
	computed: {
		isHeaderVisible() {
			return this.block.display_header === 1;
		},
		isHeaderIndent() {
			return this.block.hide_top_indent === 1;
		},
		isFooterVisible() {
			return this.block.display_footer === 1;
		},
		isFooterIndent() {
			return this.block.hide_bottom_indent === 1;
		},
		isBrand() {
			return this.block.display_brand_background === 1;
		},
	},
};
