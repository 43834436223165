var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.buttonScenario === 'show_login_popup' && !_vm.$auth.loggedIn)?_c('a',{class:[
			'btn',
			_vm.button.button_color? 'btn-' + _vm.button.button_color : 'btn-primary',
		],attrs:{"aria-label":_vm.$t('login')},on:{"click":function($event){$event.preventDefault();return _vm.showLoginPopup.apply(null, arguments)}}},[_vm._v("\n\t\t"+_vm._s(_vm.title)+"\n\t")]):_vm._e(),_vm._v(" "),(_vm.buttonScenario === 'alumni_button' && _vm.isAlumni)?_c('a',{class:[
			'btn',
			_vm.button.button_color? 'btn-' + _vm.button.button_color : 'btn-primary',
		],attrs:{"href":_vm.button.button_url,"target":"_blank","rel":"nofollow noopener"}},[_vm._v("\n\t\t"+_vm._s(_vm.title)+"\n\t")]):_vm._e(),_vm._v(" "),(_vm.buttonScenario === 'external_link')?_c('a',{class:[
			'btn',
			_vm.button.button_color? 'btn-' + _vm.button.button_color : 'btn-primary',
		],attrs:{"href":_vm.button.button_url,"target":"_blank","rel":"nofollow noopener"}},[_vm._v("\n\t\t"+_vm._s(_vm.title)+"\n\t")]):_vm._e(),_vm._v(" "),(_vm.buttonScenario === 'internal_link')?_c('a',{class:[
			'btn',
			_vm.button.button_color? 'btn-' + _vm.button.button_color : 'btn-primary',
		],attrs:{"href":_vm.button.button_url}},[_vm._v("\n\t\t"+_vm._s(_vm.title)+"\n\t")]):_vm._e(),_vm._v(" "),(_vm.buttonScenario === 'dynamic_registration_form')?_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#header-registration-form'),expression:"'#header-registration-form'"}],class:[
			'btn',
			_vm.button.button_color? 'btn-' + _vm.button.button_color : 'btn-primary',
		],attrs:{"href":"#"},on:{"click":function($event){return _vm.setPanelHeaderScenario()}}},[_vm._v("\n\t\t"+_vm._s(_vm.title)+"\n\t")]):_vm._e(),_vm._v(" "),(_vm.buttonScenario === 'payment_form')?_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#empty-registration'),expression:"'#empty-registration'"}],class:[
			'btn',
			_vm.button.button_color? 'btn-' + _vm.button.button_color : 'btn-primary',
		],attrs:{"href":"#"}},[_vm._v("\n\t\t"+_vm._s(_vm.title)+"\n\t")]):_vm._e(),_vm._v(" "),(_vm.buttonScenario === 'jivo')?_c('a',{class:[
			'btn',
			_vm.button.button_color? 'btn-' + _vm.button.button_color : 'btn-primary',
		],attrs:{"href":"#","onclick":"jivo_api.open(); return false;"}},[_vm._v("\n\t\t"+_vm._s(_vm.title)+"\n\t")]):_vm._e(),_vm._v(" "),(_vm.buttonScenario === 'select_item' && _vm.item)?_c('b-button',{class:[
			'btn',
			_vm.button.button_color? 'btn-' + _vm.button.button_color : 'btn-primary',
		],attrs:{"href":""},on:{"click":function($event){return _vm.selectItem(_vm.item)}}},[_vm._v("\n\t\t"+_vm._s(_vm.$route.query.products
				? _vm.$route.query.products.includes(`${_vm.item.name};${_vm.item.id}`)
					? 'Удалить'
					: _vm.title
				: _vm.title)+"\n\t")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }