<template>
	<div>
		<a
			v-if="buttonScenario === 'show_login_popup' && !$auth.loggedIn"
			:class="[
				'btn',
				button.button_color? 'btn-' + button.button_color : 'btn-primary',
			]"
			:aria-label="$t('login')"
			@click.prevent="showLoginPopup"
		>
			{{ title }}
		</a>
		<a
			v-if="buttonScenario === 'alumni_button' && isAlumni"
			:href="button.button_url"
			:class="[
				'btn',
				button.button_color? 'btn-' + button.button_color : 'btn-primary',
			]"
			target="_blank"
			rel="nofollow noopener"
		>
			{{ title }}
		</a>

		<a
			v-if="buttonScenario === 'external_link'"
			:href="button.button_url"
			:class="[
				'btn',
				button.button_color? 'btn-' + button.button_color : 'btn-primary',
			]"
			target="_blank"
			rel="nofollow noopener"
		>
			{{ title }}
		</a>
		<a
			v-if="buttonScenario === 'internal_link'"
			:href="button.button_url"
			:class="[
				'btn',
				button.button_color? 'btn-' + button.button_color : 'btn-primary',
			]"
		>
			{{ title }}
		</a>
		<a
			v-if="buttonScenario === 'dynamic_registration_form'"
			v-scroll-to="'#header-registration-form'"
			href="#"
			:class="[
				'btn',
				button.button_color? 'btn-' + button.button_color : 'btn-primary',
			]"
			@click="setPanelHeaderScenario()"
		>
			{{ title }}
		</a>
		<a
			v-if="buttonScenario === 'payment_form'"
			v-scroll-to="'#empty-registration'"
			href="#"
			:class="[
				'btn',
				button.button_color? 'btn-' + button.button_color : 'btn-primary',
			]"
		>
			{{ title }}
		</a>
		<a
			v-if="buttonScenario === 'jivo'"
			href="#"
			:class="[
				'btn',
				button.button_color? 'btn-' + button.button_color : 'btn-primary',
			]"
			onclick="jivo_api.open(); return false;"
		>
			{{ title }}
		</a>
		<b-button
			v-if="buttonScenario === 'select_item' && item"
			href=""
			:class="[
				'btn',
				button.button_color? 'btn-' + button.button_color : 'btn-primary',
			]"
			@click="selectItem(item)"
		>
			{{
				$route.query.products
					? $route.query.products.includes(`${item.name};${item.id}`)
						? 'Удалить'
						: title
					: title
			}}
		</b-button>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'DynamicButton',
	props: {
		button: { type: Object, required: true },
		blockId: { type: Number, default: () => null },
		alternative: { type: Boolean, default: () => false },
		scenario: { type: String, default: () => '' },
		item: { type: [String, Object], default: null },
	},
	computed: {
		...mapGetters({
			isAlumni: 'profile/isAlumni',
		}),
		title() {
			return this.alternative
				? this.button.button_alternative_title
				: this.button.button_title;
		},
		buttonScenario() {
			return this.alternative
				? this.button.button_alternative_scenario
				: this.button.button_scenario;
		},
	},
	methods: {
		...mapActions({
			showLoginPopup: 'authModal/showLogin',
		}),
		setPanelHeaderFormTitle(title) {
			if (
				typeof this.$parent.$parent.$refs['program-header'] === 'undefined'
				|| 0 in this.$parent.$parent.$refs['program-header']
			) {
				return;
			}

			this.$parent.$parent.$refs['program-header'].formTitle = title;
		},

		setPanelHeaderScenario() {
			if (
				typeof this.$parent.$parent.$refs['program-header'] === 'undefined'
				&& 0 in this.$parent.$parent.$refs['program-header']
			) {
				return;
			}

			if (this.alternative) {
				this.$parent.$parent.$refs['program-header'].scenarioBlock = this.button.button_alternative_scenario;
			} else {
				this.$parent.$parent.$refs['program-header'].scenarioBlock = this.scenario;
			}

			this.$parent.$parent.$refs['program-header'].scenarioBlockId = this.blockId;
		},
		selectItem(item) {
			const query = `${item.name};${item.id}`;
			const obj = this.$route.query.products
				? this.$route.query.products.find((el) => el === query) : null;

			if (!obj) {
				this.$emit('select', item);
			} else {
				this.$emit('select', null);
			}
		},
	},
};
</script>

<style scoped>

</style>
